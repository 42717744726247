@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #9C27B0;
}

/* CHange link color */
.familyTree {
  background-color: #fff !important;
  border: 1px solid #ddd;
}

  .familyTree tr,
  .familyTree th,
  .familyTree td {
      background-color: #fff;
      border: 1px solid #ddd;
      text-align: left;
      padding: 3px 6px 3px 6px;
  }

      .familyTree tbody:hover,
      .familyTree tr:hover,
      .familyTree tr:hover td,
      .familyTree td:hover,
      .familyTree td:active {
          background: #fff;
      }

     